import * as React from "react";
import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
}
const BNBIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size ? `${props.size}em` : "1.5em"}
    height={props.size ? `${props.size}em` : "1.5em"}
    {...props}
    viewBox="-2 -3 22 22"
    style={{ backgroundColor: "#F3BA2F", padding: 4, borderRadius: 100 }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50571 7.56338L9.00176 4.06887L12.4992 7.56622L14.5323 5.53179L9.00176 0L3.47121 5.53036L5.50571 7.56338Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9.00031L2.03376 6.96662L4.06752 9.00031L2.03376 11.034L0 9.00031Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50565 10.4366L9.0017 13.9326L12.4992 10.4352L14.5337 12.4668L9.0017 18L3.46831 12.4682L5.50565 10.4366Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9325 9.00082L15.9662 6.96713L18 9.00082L15.9662 11.0345L13.9325 9.00082Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0647 8.99929L9.00177 6.935L7.47625 8.46047L7.29995 8.63534L6.93599 8.99929L6.93883 9.00355L9.00177 11.065L11.0661 8.99929H11.0647Z"
      fill="white"
    />
  </svg>
);
export { BNBIcon };
