import * as React from "react";
import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
}
const USDCIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size ? `${props.size}em` : "1.5em"}
    height={props.size ? `${props.size}em` : "1.5em"}
    {...props}
    viewBox="-2 -3 22 22"
    style={{ backgroundColor: "#2775CA", padding: 4, borderRadius: 100 }}
  >
    <path
      d="M13 10.7676C13 9.02492 11.7463 8.42741 9.23878 8.17849C7.44773 7.97928 7.08952 7.58098 7.08952 6.8838C7.08952 6.18663 7.68658 5.73862 8.88057 5.73862C9.95521 5.73862 10.5523 6.03737 10.8507 6.78426C10.9105 6.93364 11.0896 7.03318 11.2687 7.03318H12.2238C12.4627 7.03318 12.6418 6.8838 12.6418 6.68472V6.63488C12.4029 5.53941 11.3283 4.69298 9.95521 4.59344V3.39842C9.95521 3.19921 9.7761 3.04983 9.47764 3H8.58211C8.34326 3 8.16415 3.14938 8.1044 3.39842V4.5436C6.31334 4.74281 5.17911 5.73862 5.17911 6.98347C5.17911 8.62662 6.3731 9.27384 8.88057 9.52288C10.5523 9.7718 11.0896 10.0706 11.0896 10.8673C11.0896 11.664 10.2537 12.2117 9.11943 12.2117C7.56708 12.2117 7.02977 11.6639 6.85066 10.917C6.79106 10.7179 6.61195 10.6182 6.43284 10.6182H5.41782C5.17911 10.6182 5 10.7676 5 10.9668V11.0167C5.23871 12.2614 6.19399 13.1576 8.16415 13.4067V14.6017C8.16415 14.8008 8.34326 14.9502 8.64172 15H9.53724C9.7761 15 9.95521 14.8506 10.015 14.6017V13.4067C11.806 13.1576 13 12.112 13 10.7676Z"
      fill="white"
    />
    <path
      d="M6.4569 16.2832C2.56745 14.8267 0.572819 10.3006 2.01898 6.29474C2.76695 4.10976 4.41248 2.44506 6.4569 1.66471C6.6564 1.5607 6.75609 1.40463 6.75609 1.14443V0.416145C6.75609 0.20801 6.6564 0.0519401 6.4569 0C6.40699 0 6.3073 0 6.2574 0.0519401C1.52029 1.61264 -1.07272 6.86708 0.423224 11.8093C1.32079 14.7225 3.46501 16.9596 6.2574 17.896C6.4569 18 6.6564 17.896 6.70618 17.6879C6.75609 17.6359 6.75609 17.5839 6.75609 17.4798V16.7514C6.75609 16.5954 6.60649 16.3874 6.4569 16.2832ZM11.7426 0.0519401C11.5431 -0.052065 11.3436 0.0519401 11.2938 0.260075C11.2439 0.31214 11.2439 0.36408 11.2439 0.46821V1.1965C11.2439 1.40463 11.3935 1.61264 11.5431 1.71677C15.4326 3.17334 17.4272 7.69937 15.981 11.7053C15.2331 13.8902 13.5875 15.5549 11.5431 16.3353C11.3436 16.4393 11.2439 16.5954 11.2439 16.8556V17.5839C11.2439 17.792 11.3436 17.9481 11.5431 18C11.593 18 11.6927 18 11.7426 17.9481C16.4797 16.3874 19.0727 11.1329 17.5768 6.19074C16.6792 3.22541 14.4851 0.988361 11.7426 0.0519401Z"
      fill="white"
    />
  </svg>
);
export { USDCIcon };
