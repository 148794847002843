import React from "react";
import { GradientButton } from "./GradientButton";
import { ETHIcon, SocialIcons, USDTIcon, USDCIcon, BNBIcon } from "../assets";
import { motion } from "framer-motion";
import Progressbar from "./Progressbar";

type mode =
  | "facebook"
  | "instagram"
  | "twitter"
  | "vimeo"
  | "telegram"
  | "linktree"
  | "medium"
  | "youtube"
  | "tiktok"
  | "discord";

const HeroSection = () => {
  // const socialIcons: mode[] = ["facebook", "instagram", "twitter", "vimeo", "telegram", "linktree", "medium", "youtube", "tiktok"];
  const socialIcons: mode[] = [
    "facebook",
    "instagram",
    "twitter",
    "telegram",
    "linktree",
    "medium",
    "discord",
  ];
  const socialLinks: any = {
    facebook: "https://www.facebook.com/mnightstoken",
    instagram: "https://www.instagram.com/mnightstoken/",
    twitter:
      "https://twitter.com/i/flow/login?redirect_after_login=%2FMnightstoken",
    vimeo: "",
    telegram: "https://t.me/+1loPQ5yORcw3MDAx",
    linktree: "https://linktr.ee/mnights",
    medium: "https://medium.com/@mnightstoken",
    youtube: "",
    tiktok: "",
    discord: "https://discord.gg/skNBxPd3Bm",
  };
  const openLinkInNewTab = (url: string) => {
    window.open(url, "_blank");
  };
  const handleScrollToElementId = () => {
    const heroElement = document.getElementById("hero");

    if (heroElement) {
      const elemTop = heroElement.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({ top: elemTop - 50, behavior: "smooth" });
    }
  };

  return (
    <section className="my-20 lg:my-40 w-screen">
      <div className="flex flex-col lg2:flex-row items-center justify-center gap-20 w-full text-white ">
        {/* left */}
        <section className="py-4 px-8 flex basis-[60%] shrink-[1] flex-col  items-center justify-center ">
          <div className="flex flex-col items-center lg2:items-start ">
            <p className="text-[28px] leading-[120%] sm:text-4xl lg2:text-5xl 2xl:text-6xl text-center font-bold uppercase m-0 ">
              Millionaire
              <span className="texttualgradient">Nights</span>
            </p>
            <p className="text-[86px] sm:text-[110px] text-center xl:text-[150px] 2xl:text-[180px] w-[full] uppercase leading-[120%] font-bold">
              token
            </p>
            <p className="text-[14px] xl:text-lg text-center lg2:text-left 2xl:text-2xl break-words font-semibold uppercase w-[280px] leading-[150%] sm:w-[400px] lg2:w-[626px]">
              BENEFIT FROM THE EXPANSION OF OUR LUXURY MARKETPLACE AND DATING
              NETWORK<span className=" ">!</span>
            </p>

            <p className="text-xs sm:text-lg font-light text-center w-[230px] lg2:text-left break-words sm:w-[400px] lg2:w-[626px] leading-[170%]">
              Conquer the world with us and benefit from the revenue!
              <span className=" ">!</span>
            </p>

            <div className="flex justify-center xs:mb-8 sm:mb-0 flex-col sm:flex-row lg2:justify-start md:items-center items-center lg2:text-left w-full gap-5 lg:gap-7 mt-5 lg:mt-[32px]">
              <motion.div
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.5 },
                }}
                onClick={() =>
                  openLinkInNewTab(
                    "https://doc.millionairenightstoken.com"
                  )
                }
                className="cursor-pointer bg-gradient-to-t from-[#CDB8FB] to-[#FFF] px-6 py-2 lg:px-5 rounded-3xl lg:py-2 text-black "
              >
                <p className="text-sm md:text-lg leading-[150%] font-normal lg:font-semibold antialiased">
                  White Paper
                </p>
              </motion.div>
              <div className="lg:mb-0 flex flex-wrap gap-2 lg:gap-3 justify-center items-center">
                {socialIcons.map((item, index) => (
                  <motion.button
                    onClick={() => openLinkInNewTab(socialLinks[item])}
                    whileHover={{
                      scale: 1.3,
                      transition: { duration: 0.5 },
                    }}
                    key={index}
                    className="gradient-hover-effect cursor-pointer rounded-full flex justify-center items-center w-[44px] h-[44px] mb-2 lg:mb-0 lg:w-[44px]"
                    style={{
                      backgroundImage:
                        "radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 71.35%, #5318D1 100%)",
                    }}
                  >
                    <SocialIcons mode={item} size={20} />
                  </motion.button>
                ))}
              </div>
            </div>
          </div>
        </section>
         {/* right */}
        <section id="hero" className="flex basis-[45%] shrink-[.5] flex-col items-start px-4">
          <div
            id="walletConnectForm"
            style={{
              border: "1px solid var(--GRAD_01, #5318d180)",
              position: "relative",
              overflow: "hidden",
            }}
            className="w-[320px] md:w-[380px] px-5 pt-4 pb-5 md:pt-8 md:pb-10 md:px-8 2xl:w-[444px] flex border-2 border-white rounded-3xl"
          >
            <aside className="w-full flex items-center flex-col">

              <p className="lg2:block text-lg md:text-xl 2xl:text-2xl font-normal text-center leading-[120%]">
                $MNIGHTS Private-Sale
              </p>
              <section className="w-full lg2:block mb-3.5 mt-6">
                <Progressbar progress={0.0} />
              </section>
              <section className="mb-5">
                <p className="text-lg text-white font-semibold antialiased lg2:block">
                  USD Raised:{" "}
                  <span className="text-purple-lighter">
                    $<span className="ctBalance" title="ctBalance">0</span> / $<span className="stageMax">0</span>
                  </span>
                </p>
                <p className="text-sm text-white font-semibold antialiased lg2:hidden">
                  USD Raised:{" "}
                  <span className="text-purple-lighter">
                    $<span className="ctBalance" title="ctBalance">0</span> / $<span className="stageMax">0</span>
                  </span>
                </p>
              </section>

              <section className="opacity-60 text-center mb-[5px]">
                <p className="text-xs font-normal antialiased ">
                  Next price: $0.75
                </p>
              </section>
              <section className="bg-[#fff]/[0.07] lg2:bg-transparent w-full py-4 lg2:py-0 rounded-[30px] mt-[15px] lg2:mt-0 lg2:mb-[11px]">
                {/* <input className=' bg-transparent px-4 text-white   ' placeholder='' /> */}
                <p className="text-xl md:text-3xl text-center font-semibold text-white">
                  1 MNIGHTS <span className=" ">= $</span>0.5
                </p>
              </section>
              <section className="w-full mt-5 lg2:mt-0 lg2:flex flex-col justify-center gap-4 ">
                <div className="flex justify-center">Please select your chain</div>
                <div className="flex">
                <GradientButton
                  type={'button'}
                  data-type="BSC"
                  text="BSC"
                  className="border select-chain flex justify-center items-center leading-[150%]"
                  Icon={<BNBIcon size={2} />}
                />
                <GradientButton
                  type={'button'}
                  text="ETH"
                  data-type="ETH"
                  className="select-chain flex justify-center items-center leading-[150%]"
                  Icon={<ETHIcon size={2} />}
                />
                </div>
              </section>
              <section className="lg2:flex lg2:justify-between lg2:flex-1 w-full gap-3 mt-6">
                <div data-type="ETH" className="hidden select-currency token-wrapper mt-2 lg2:mt-0">
                  <ETHIcon
                      style={{
                        position: 'relative',
                        left: '-5px'
                      }}
                      size={2} />
                  <p className="text-sm font-normal leading-[150%] antialiased">
                    ETH
                  </p>
                </div>
                <div data-type="BNB" className="border select-currency token-wrapper mt-2 lg2:mt-0">
                  <BNBIcon
                      style={{
                        position: 'relative',
                        left: '-5px'
                      }}
                      size={2} />
                  <p className="text-sm font-normal leading-[150%] antialiased">
                    BNB
                  </p>
                </div>
                <div data-type="USDC" className="select-currency token-wrapper lg2:middle mt-2 lg2:mt-0">
                  <USDCIcon size={2} />
                  <p className="text-sm font-normal leading-[150%] antialiased">
                    USDC
                  </p>
                </div>
                <div data-type="USDT" className="select-currency token-wrapper mt-2 lg2:mt-0">
                  <USDTIcon size={2} />
                  <p className="text-sm font-normal antialiased leading-[150%]">
                    USDT
                  </p>
                </div>
              </section>
              <section className="flex justify-between items-center w-full gap-3 mt-6">
                <div className="w-1/2">
                  <p className="mb-1 text-xs md:text-sm font-normal text-[#fff]/[0.6] text-center leading-[150%]">
                    <span className="hidden md:block">Amount in</span>
                    <span className="currency-you-pay">BNB</span> you pay
                    <span className="currency-you-pay-extra-text"> (USD value)</span>
                  </p>
                  <input id="amount" className="form-input" placeholder="0.0" />
                </div>
                <div className="w-1/2">
                  <p className="mb-1 text-xs md:text-sm font-normal text-[#fff]/[0.6] text-center leading-[150%]">
                    <span className="hidden md:block">Amount in</span>
                    TOKEN you receive
                  </p>
                  <input id="tokenAmount" className="form-input" placeholder="0.0" />
                  
                </div>
              </section>
              <footer className="mt-9 flex justify-between items-center w-full gap-3 flex-col">
                <div id="message">Waiting for the wallet!</div>
                <p id="balanceCurrencyContainer" className="hidden text-lg text-white font-semibold antialiased lg2:block">
                  <span id="balanceCurrency"></span> balance: {" "}
                  <span id="balanceCurrencyValue" className="text-purple-lighter"></span>
                </p>
                <div id="referralContainer" className="flex flex-row w-full hidden">
                  <input id="referral" className="form-input" placeholder="Referral name" />
                </div>
                <div className="flex flex-row w-full">
                  <GradientButton
                    text="Connect Wallet"
                    className="btn-connect w-[calc(50%-4px)]"
                  />
                  <GradientButton
                    text="Disconnect Wallet"
                    className="hidden btn-disconnect w-[calc(50%-4px)]"
                  />
                  <GradientButton
                    text="Allow"
                    className="mr-3 hidden btn-allowC ml-3 w-[calc(50%-4px)]"
                  />
                  <GradientButton
                    text="Allow"
                    className="mr-3 hidden btn-allow ml-3 w-[calc(50%-4px)]"
                  />
                  <motion.button
                    whileHover={{
                      scale: 1.05,
                      transition: { duration: 0.5 },
                    }}
                    className="ml-3 btn-buy gradient-hover-effect cursor-pointer py-[13px] bg-gradient-to-t from-[#775CF4] to-[#C85EF7] flex items-center justify-center px-1  md:px-4 bg-[#fff]/[0.07] w-[calc(50%-4px)]  rounded-[30px]"
                  >
                    <p className="text-sm 2xl:text-base text-center font-normal antialiased leading-[150%] w-full">
                      Buy Crypto
                    </p>
                  </motion.button>
                </div>
                <div className="flex flex-row w-full">
                  <GradientButton
                    text="Add network"
                    className="btn-addNetwork w-[calc(50%-4px)]"
                  />
                  <GradientButton
                    text="Add token"
                    className="ml-3 btn-addToken w-[calc(50%-4px)]"
                  />
                </div>

                <div className="flex w-full gap-3 flex-col mt-3 aPanel hidden">
                  <div className="hidden">
                    <input id="chain" type="hidden" value="BSC"/>
                    <div>Wallet address: <span id="wa" className="hidden" title="wa"/></div>
                    <div>Balance: <span id="balance" title="balance"/></div>
                  
                    <div>Coin Price: <span id="price" title="price"/></div>
                    <div>USDT Balance: <span id="usdtBalance" title="usdtBalance"/></div>
                    <div>USDT Approve Limit: <span id="usdtApproveLimit" title="usdtApproveLimit"/></div>
                    
                    <div>USDC Balance: <span id="usdcBalance" title="usdcBalance"/></div>
                    <div>USDC Approve Limit: <span id="usdcApproveLimit" title="usdcApproveLimit"></span></div>

                    <div>Token balance: <span id="tokenBalance" title="tokenBalance"></span></div>
                    <input id="aff" type="hidden" title="aff"/>
                    
                    <div>Sponsore type: <span id="st" title="st">0x0000000000000000000000000000000000000000</span></div>
                    <div>Sponsor: <span id="sponsor" title="sponsor"></span></div>
                    <hr/>
                    
                    <div><strong>Buy</strong></div>
                    <div>Stable $ Amount: <input id="amount" type="text" title="amount"/></div>
                  </div>

                  <div>Affiliate address:<br/><input style={{height: "30px", fontSize: "13px", padding: "5px"}} className="w-full" id="affiliateAddress" type="text" title="affiliateAddress"/></div>
                  <div>Affiliate token %:<input style={{marginLeft: "5px", width: "50px", height: "30px", fontSize: "13px", padding: "5px"}} maxLength={3} id="affiliateToken" type="text" title="affiliateToken" /></div>
                  <GradientButton
                    text="Get affiliate"
                    className="btn-getAffiliate w-[calc(50%-4px)]"
                  />
                  <GradientButton
                    text="Set affiliate"
                    className="btn-setAffiliate w-[calc(50%-4px)]"
                  />
                  <GradientButton
                    text="Modify affiliate"
                    className="btn-modifyAffiliate w-[calc(50%-4px)]"
                  />
                </div>
                <div className="flex w-full items-center justify-center">
                    
                  </div>
              </footer>
            </aside>
          </div>
        </section>
      </div>
    </section>
  );
};

export { HeroSection };
