import React, { useState } from 'react';

const DashIcon = () => {
  return (
    <svg
      width='20'
      height='2'
      viewBox='0 0 20 2'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='20'
        height='2'
        fill='#D9D9D9'
      />
    </svg>
  );
};

const AddIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 9H20V11H0V9Z'
        fill='#D9D9D9'
      />
      <path
        d='M11 8.74224e-08L11 20H9L9 0L11 8.74224e-08Z'
        fill='#D9D9D9'
      />
    </svg>
  );
};

const FAQ = () => {
  const faqData = [
    {
      question: (
        <p>
          HOW DO I BUY A{' '}
          <span className='font-bold'>
            $MILLIONAIRE<span className='texttualgradient'>NIGHTS</span>{' '}
          </span>{' '}
          TOKEN?
        </p>
      ),
      // 'HOW DO I BUY A ŠMILLIONAIRE NIGHTSTOKEN?',
      answer:
        '$MNights token can only be purchased through our official website with BNB, ETH, USDT and USDC.',
    },
    {
      question: (
        <p>
          WHAT IS THE DIFFERENCE BETWEEN{' '}
          <span className='font-bold'>
            $MILLIONAIRE<span className='texttualgradient'>NIGHTS</span>{' '}
          </span>{' '}
          AND OTHER DATING PLATFORMS?
        </p>
      ),
      answer:
        'MillionaireNights is a new dating platform that focuses on connecting verified users all over the world. MillionaireNights also offers a special marketplace and party series for members and investors in the MNights token.',
    },
    {
      question: 'WHEN DO I GET PAID?',
      answer:
        'Payments will be made on the first week of each month.',
    },
    {
      question: (
        <p>
          WHAT MAKES{' '}
          <span className='font-bold'>
            $MILLIONAIRE<span className='texttualgradient'>NIGHTS</span>{' '}
          </span>{' '}
          SUSTAINABLE IN THE LONG-TERM?
        </p>
      ),
      answer:
        "MillionaireNights’ sustainability is backed with multiple income streams. There’s not only income from memberships on the dating site but there are fees for advertising, highlights and transactions on the marketplace platform as well as the party series. Detailed information can be found by clicking on the White Paper.",
    },
    {
      question: 'IS THERE AN APP FOR DATING?',
      answer:
        'It’s coming soon.',
    },
    {
      question: 'WHEN WILL THE LUXURY MARKETPLACE BE AVAILABLE?',
      answer: 'The luxury marketplace will be available in 2024.',
    },
  ];

  return (
    <div className='mt-20 mb-28 xs:my-10 '>
      <p className='text-white text-[42px] mb-12 font-normal uppercase text-center'>
        FAQ
      </p>
      <div className='w-[90%] md:w-[75%]  mx-auto  flex flex-col gap-5'>
        {faqData.map((item, index) => (
          <FAQItem
            key={index}
            question={item.question}
            answer={item.answer}
          />
        ))}
      </div>
    </div>
  );
};

export { FAQ };

const FAQItem = ({ question, answer }: any) => {
  const [open, setOpen] = useState(false);

  return (
    <div className='relative '>
      <div className='flex w-full gap-3 flex-col text-white'>
        <div
          className='w-[90%] md:w-full text-base md:text-lg font-normal'
          onClick={() => setOpen(!open)}
        >
          {question}
        </div>
        {!open && <div className='w-full h-[1px] bg-[#5318D1]' />}
        {open && (
          <div>
            <div className='text-sm  md:text-base font-light text-[#989898]'>
              {answer}
            </div>
          </div>
        )}
      </div>
      <span
        onClick={() => setOpen(!open)}
        className='absolute right-2 top-1 cursor-pointer'
      >
        {open ? <DashIcon /> : <AddIcon />}
      </span>
    </div>
  );
};
