import React from "react";
import { useWindowDimensions } from "../utils";
import { TeamsSectionWeb } from "./TeamsSectionWeb";
import { TeamsSectionMobile } from "./TeamsSectionMobile";

const TeamsSection = () => {
	const { width } = useWindowDimensions()
	return width && width < 1024 ? (<TeamsSectionMobile />) : (<TeamsSectionWeb />)
};

export { TeamsSection };
