import React from "react";
import { motion } from "framer-motion";

interface ButtonI extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  className?: string;
  buttonClassName?: string;
  Icon?: React.ReactNode; // A prop típusának pontosítása
}

const GradientButton = ({
  text,
  className,
  buttonClassName,
  Icon,
  ...rest
}: ButtonI) => {

  const buttonClasses = `w-[calc(100%-4px)] m-[2px] bg-black text-white px-2 py-[13px] rounded-full focus:bg-gradient-to-t from-[#775CF4] to-[#C85EF7] text-sm 2xl:text-base text-center font-normal antialiased leading-[150%] ${Icon ? 'with-icon' : ''} ${buttonClassName || ''}`; // Ellenőrizd, hogy a buttonClassName létezik-e

  return (
    <motion.div
      whileHover={{
        scale: 1.05,
        transition: { duration: 0.5 },
      }}
      className={`bg-colorGradiant flex-none rounded-full ${className || ''}`} // Ellenőrizd, hogy a className létezik-e
    >
      <button
        className={buttonClasses}
        {...rest}
      >
        {text}  
        {Icon && <span className="icon">{Icon}</span>}
      </button>
    </motion.div>
  );
};

export { GradientButton };