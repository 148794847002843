import React from "react";
import { OfferDotsIcon } from "../assets";
import { GradientButton } from "./GradientButton";
import { motion } from "framer-motion";
import { PolgariDanielPng, MeszarosRichardPng, ApatiGaborPng } from "../assets";

import { DotsShades } from "../assets/svgs/DotsShades";

const TeamsSectionWeb = () => {
  return (
    <div className='mt-16 mb-24 w-[90%] md:w-[75%] m-auto '>
      <div className='text-white md:flex flex-col justify-center items-center'>
        
          <p className='text-center text-[24px] md:text-[42px]  font-normal uppercase md:mb-10'>
            TEAMS
          </p>

		  <div className="flex justify-between w-[90%] text-center">
			<div>
				<div className="box rounded-full flex w-[205px] h-[205px] justify-center items-center" style={{boxShadow: "0px 4px 4px 0px #00000040", background: "radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 85%, #5318D1 100%"}}>
					<img className="rounded-full w-[80%]" src={PolgariDanielPng}/>
				</div>
				<p className="mt-5">Daniel Polgari<br/>CEO</p>
			</div>
			<div className="hidden xl:block">
				<DotsShades/>
			</div>
			<div>
				<div className="box rounded-full flex w-[205px] h-[205px] justify-center items-center" style={{boxShadow: "0px 4px 4px 0px #00000040", background: "radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 85%, #5318D1 100%"}}>
					<img className="rounded-full w-[80%]" src={MeszarosRichardPng}/>
				</div>
				<p className="mt-5">Richard Meszaros<br/>COO</p>
			</div>
			<div className="hidden xl:block">
				<DotsShades/>
			</div>
			<div>
				<div className="box rounded-full flex w-[205px] h-[205px] justify-center items-center" style={{boxShadow: "0px 4px 4px 0px #00000040", background: "radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 85%, #5318D1 100%"}}>
					<img className="rounded-full w-[80%]" src={ApatiGaborPng}/>
				</div>
				<p className="mt-5">Gabor Apati<br/>CMO</p>
			</div>
		  </div>
		</div>
	</div>
  );
};

export { TeamsSectionWeb };
