import React from "react";

interface ProgressbarProps {
  progress: number;
}

const Progressbar: React.FC<ProgressbarProps> = ({ progress }) => {
  return (
    <div
      className="h-[12px] bg-progress-gray rounded-[30px] transition-[background-color] duration-300 min-h-[20px] w-full progressbar"
    >
      <div
        className="h-full rounded-[50px] transition-[width, background-color] duration-300 progressbarPercent"
        style={{ width: `calc(100% * ${progress})`, backgroundImage: "linear-gradient(90deg, #775CF4 0%, #C85EF7 100%)"

    }}
      ></div>
    </div>
  );
};

export default Progressbar;