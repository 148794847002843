export const DotsShades = () => {
  <svg
    width='108'
    height='180'
    viewBox='0 0 108 180'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M105 178.477C105 178.117 105.09 177.859 105.27 177.703C105.462 177.547 105.708 177.469 106.008 177.469H106.188C106.488 177.469 106.728 177.547 106.908 177.703C107.1 177.859 107.196 178.117 107.196 178.477C107.196 178.837 107.1 179.095 106.908 179.251C106.728 179.407 106.488 179.485 106.188 179.485H106.008C105.708 179.485 105.462 179.407 105.27 179.251C105.09 179.095 105 178.837 105 178.477Z'
      fill='url(#paint0_linear_72_662)'
    />
    <path
      d='M105 156.293C105 155.933 105.09 155.675 105.27 155.519C105.462 155.363 105.708 155.285 106.008 155.285H106.188C106.488 155.285 106.728 155.363 106.908 155.519C107.1 155.675 107.196 155.933 107.196 156.293C107.196 156.653 107.1 156.911 106.908 157.067C106.728 157.223 106.488 157.301 106.188 157.301H106.008C105.708 157.301 105.462 157.223 105.27 157.067C105.09 156.911 105 156.653 105 156.293Z'
      fill='url(#paint1_linear_72_662)'
    />
    <path
      d='M105 134.11C105 133.75 105.09 133.492 105.27 133.336C105.462 133.18 105.708 133.102 106.008 133.102H106.188C106.488 133.102 106.728 133.18 106.908 133.336C107.1 133.492 107.196 133.75 107.196 134.11C107.196 134.47 107.1 134.728 106.908 134.884C106.728 135.04 106.488 135.118 106.188 135.118H106.008C105.708 135.118 105.462 135.04 105.27 134.884C105.09 134.728 105 134.47 105 134.11Z'
      fill='url(#paint2_linear_72_662)'
    />
    <path
      d='M105 111.926C105 111.566 105.09 111.308 105.27 111.152C105.462 110.996 105.708 110.918 106.008 110.918H106.188C106.488 110.918 106.728 110.996 106.908 111.152C107.1 111.308 107.196 111.566 107.196 111.926C107.196 112.286 107.1 112.544 106.908 112.7C106.728 112.856 106.488 112.934 106.188 112.934H106.008C105.708 112.934 105.462 112.856 105.27 112.7C105.09 112.544 105 112.286 105 111.926Z'
      fill='url(#paint3_linear_72_662)'
    />
    <path
      d='M105 89.7424C105 89.3824 105.09 89.1244 105.27 88.9684C105.462 88.8124 105.708 88.7344 106.008 88.7344H106.188C106.488 88.7344 106.728 88.8124 106.908 88.9684C107.1 89.1244 107.196 89.3824 107.196 89.7424C107.196 90.1024 107.1 90.3604 106.908 90.5164C106.728 90.6724 106.488 90.7504 106.188 90.7504H106.008C105.708 90.7504 105.462 90.6724 105.27 90.5164C105.09 90.3604 105 90.1024 105 89.7424Z'
      fill='url(#paint4_linear_72_662)'
    />
    <path
      d='M105 67.5588C105 67.1988 105.09 66.9408 105.27 66.7848C105.462 66.6288 105.708 66.5508 106.008 66.5508H106.188C106.488 66.5508 106.728 66.6288 106.908 66.7848C107.1 66.9408 107.196 67.1988 107.196 67.5588C107.196 67.9188 107.1 68.1768 106.908 68.3328C106.728 68.4888 106.488 68.5668 106.188 68.5668H106.008C105.708 68.5668 105.462 68.4888 105.27 68.3328C105.09 68.1768 105 67.9188 105 67.5588Z'
      fill='url(#paint5_linear_72_662)'
    />
    <path
      d='M105 45.3752C105 45.0152 105.09 44.7572 105.27 44.6012C105.462 44.4452 105.708 44.3672 106.008 44.3672H106.188C106.488 44.3672 106.728 44.4452 106.908 44.6012C107.1 44.7572 107.196 45.0152 107.196 45.3752C107.196 45.7352 107.1 45.9932 106.908 46.1492C106.728 46.3052 106.488 46.3832 106.188 46.3832H106.008C105.708 46.3832 105.462 46.3052 105.27 46.1492C105.09 45.9932 105 45.7352 105 45.3752Z'
      fill='url(#paint6_linear_72_662)'
    />
    <path
      d='M105 23.1916C105 22.8316 105.09 22.5736 105.27 22.4176C105.462 22.2616 105.708 22.1836 106.008 22.1836H106.188C106.488 22.1836 106.728 22.2616 106.908 22.4176C107.1 22.5736 107.196 22.8316 107.196 23.1916C107.196 23.5516 107.1 23.8096 106.908 23.9656C106.728 24.1216 106.488 24.1996 106.188 24.1996H106.008C105.708 24.1996 105.462 24.1216 105.27 23.9656C105.09 23.8096 105 23.5516 105 23.1916Z'
      fill='url(#paint7_linear_72_662)'
    />
    <path
      d='M105 1.008C105 0.648 105.09 0.39 105.27 0.234C105.462 0.078 105.708 -1.31134e-08 106.008 0L106.188 7.86804e-09C106.488 2.09815e-08 106.728 0.0780001 106.908 0.234C107.1 0.39 107.196 0.648 107.196 1.008C107.196 1.368 107.1 1.626 106.908 1.782C106.728 1.938 106.488 2.016 106.188 2.016H106.008C105.708 2.016 105.462 1.938 105.27 1.782C105.09 1.626 105 1.368 105 1.008Z'
      fill='url(#paint8_linear_72_662)'
    />
    <path
      d='M70 178.477C70 178.117 70.09 177.859 70.27 177.703C70.462 177.547 70.708 177.469 71.008 177.469H71.188C71.488 177.469 71.728 177.547 71.908 177.703C72.1 177.859 72.196 178.117 72.196 178.477C72.196 178.837 72.1 179.095 71.908 179.251C71.728 179.407 71.488 179.485 71.188 179.485H71.008C70.708 179.485 70.462 179.407 70.27 179.251C70.09 179.095 70 178.837 70 178.477Z'
      fill='url(#paint9_linear_72_662)'
    />
    <path
      d='M70 156.293C70 155.933 70.09 155.675 70.27 155.519C70.462 155.363 70.708 155.285 71.008 155.285H71.188C71.488 155.285 71.728 155.363 71.908 155.519C72.1 155.675 72.196 155.933 72.196 156.293C72.196 156.653 72.1 156.911 71.908 157.067C71.728 157.223 71.488 157.301 71.188 157.301H71.008C70.708 157.301 70.462 157.223 70.27 157.067C70.09 156.911 70 156.653 70 156.293Z'
      fill='url(#paint10_linear_72_662)'
    />
    <path
      d='M70 134.11C70 133.75 70.09 133.492 70.27 133.336C70.462 133.18 70.708 133.102 71.008 133.102H71.188C71.488 133.102 71.728 133.18 71.908 133.336C72.1 133.492 72.196 133.75 72.196 134.11C72.196 134.47 72.1 134.728 71.908 134.884C71.728 135.04 71.488 135.118 71.188 135.118H71.008C70.708 135.118 70.462 135.04 70.27 134.884C70.09 134.728 70 134.47 70 134.11Z'
      fill='url(#paint11_linear_72_662)'
    />
    <path
      d='M70 111.926C70 111.566 70.09 111.308 70.27 111.152C70.462 110.996 70.708 110.918 71.008 110.918H71.188C71.488 110.918 71.728 110.996 71.908 111.152C72.1 111.308 72.196 111.566 72.196 111.926C72.196 112.286 72.1 112.544 71.908 112.7C71.728 112.856 71.488 112.934 71.188 112.934H71.008C70.708 112.934 70.462 112.856 70.27 112.7C70.09 112.544 70 112.286 70 111.926Z'
      fill='url(#paint12_linear_72_662)'
    />
    <path
      d='M70 89.7424C70 89.3824 70.09 89.1244 70.27 88.9684C70.462 88.8124 70.708 88.7344 71.008 88.7344H71.188C71.488 88.7344 71.728 88.8124 71.908 88.9684C72.1 89.1244 72.196 89.3824 72.196 89.7424C72.196 90.1024 72.1 90.3604 71.908 90.5164C71.728 90.6724 71.488 90.7504 71.188 90.7504H71.008C70.708 90.7504 70.462 90.6724 70.27 90.5164C70.09 90.3604 70 90.1024 70 89.7424Z'
      fill='url(#paint13_linear_72_662)'
    />
    <path
      d='M70 67.5588C70 67.1988 70.09 66.9408 70.27 66.7848C70.462 66.6288 70.708 66.5508 71.008 66.5508H71.188C71.488 66.5508 71.728 66.6288 71.908 66.7848C72.1 66.9408 72.196 67.1988 72.196 67.5588C72.196 67.9188 72.1 68.1768 71.908 68.3328C71.728 68.4888 71.488 68.5668 71.188 68.5668H71.008C70.708 68.5668 70.462 68.4888 70.27 68.3328C70.09 68.1768 70 67.9188 70 67.5588Z'
      fill='url(#paint14_linear_72_662)'
    />
    <path
      d='M70 45.3752C70 45.0152 70.09 44.7572 70.27 44.6012C70.462 44.4452 70.708 44.3672 71.008 44.3672H71.188C71.488 44.3672 71.728 44.4452 71.908 44.6012C72.1 44.7572 72.196 45.0152 72.196 45.3752C72.196 45.7352 72.1 45.9932 71.908 46.1492C71.728 46.3052 71.488 46.3832 71.188 46.3832H71.008C70.708 46.3832 70.462 46.3052 70.27 46.1492C70.09 45.9932 70 45.7352 70 45.3752Z'
      fill='url(#paint15_linear_72_662)'
    />
    <path
      d='M70 23.1916C70 22.8316 70.09 22.5736 70.27 22.4176C70.462 22.2616 70.708 22.1836 71.008 22.1836H71.188C71.488 22.1836 71.728 22.2616 71.908 22.4176C72.1 22.5736 72.196 22.8316 72.196 23.1916C72.196 23.5516 72.1 23.8096 71.908 23.9656C71.728 24.1216 71.488 24.1996 71.188 24.1996H71.008C70.708 24.1996 70.462 24.1216 70.27 23.9656C70.09 23.8096 70 23.5516 70 23.1916Z'
      fill='url(#paint16_linear_72_662)'
    />
    <path
      d='M70 1.008C70 0.648 70.09 0.39 70.27 0.234C70.462 0.078 70.708 -1.31134e-08 71.008 0L71.188 7.86804e-09C71.488 2.09815e-08 71.728 0.0780001 71.908 0.234C72.1 0.39 72.196 0.648 72.196 1.008C72.196 1.368 72.1 1.626 71.908 1.782C71.728 1.938 71.488 2.016 71.188 2.016H71.008C70.708 2.016 70.462 1.938 70.27 1.782C70.09 1.626 70 1.368 70 1.008Z'
      fill='url(#paint17_linear_72_662)'
    />
    <path
      d='M35 178.477C35 178.117 35.09 177.859 35.27 177.703C35.462 177.547 35.708 177.469 36.008 177.469H36.188C36.488 177.469 36.728 177.547 36.908 177.703C37.1 177.859 37.196 178.117 37.196 178.477C37.196 178.837 37.1 179.095 36.908 179.251C36.728 179.407 36.488 179.485 36.188 179.485H36.008C35.708 179.485 35.462 179.407 35.27 179.251C35.09 179.095 35 178.837 35 178.477Z'
      fill='url(#paint18_linear_72_662)'
    />
    <path
      d='M35 156.293C35 155.933 35.09 155.675 35.27 155.519C35.462 155.363 35.708 155.285 36.008 155.285H36.188C36.488 155.285 36.728 155.363 36.908 155.519C37.1 155.675 37.196 155.933 37.196 156.293C37.196 156.653 37.1 156.911 36.908 157.067C36.728 157.223 36.488 157.301 36.188 157.301H36.008C35.708 157.301 35.462 157.223 35.27 157.067C35.09 156.911 35 156.653 35 156.293Z'
      fill='url(#paint19_linear_72_662)'
    />
    <path
      d='M35 134.11C35 133.75 35.09 133.492 35.27 133.336C35.462 133.18 35.708 133.102 36.008 133.102H36.188C36.488 133.102 36.728 133.18 36.908 133.336C37.1 133.492 37.196 133.75 37.196 134.11C37.196 134.47 37.1 134.728 36.908 134.884C36.728 135.04 36.488 135.118 36.188 135.118H36.008C35.708 135.118 35.462 135.04 35.27 134.884C35.09 134.728 35 134.47 35 134.11Z'
      fill='url(#paint20_linear_72_662)'
    />
    <path
      d='M35 111.926C35 111.566 35.09 111.308 35.27 111.152C35.462 110.996 35.708 110.918 36.008 110.918H36.188C36.488 110.918 36.728 110.996 36.908 111.152C37.1 111.308 37.196 111.566 37.196 111.926C37.196 112.286 37.1 112.544 36.908 112.7C36.728 112.856 36.488 112.934 36.188 112.934H36.008C35.708 112.934 35.462 112.856 35.27 112.7C35.09 112.544 35 112.286 35 111.926Z'
      fill='url(#paint21_linear_72_662)'
    />
    <path
      d='M35 89.7424C35 89.3824 35.09 89.1244 35.27 88.9684C35.462 88.8124 35.708 88.7344 36.008 88.7344H36.188C36.488 88.7344 36.728 88.8124 36.908 88.9684C37.1 89.1244 37.196 89.3824 37.196 89.7424C37.196 90.1024 37.1 90.3604 36.908 90.5164C36.728 90.6724 36.488 90.7504 36.188 90.7504H36.008C35.708 90.7504 35.462 90.6724 35.27 90.5164C35.09 90.3604 35 90.1024 35 89.7424Z'
      fill='url(#paint22_linear_72_662)'
    />
    <path
      d='M35 67.5588C35 67.1988 35.09 66.9408 35.27 66.7848C35.462 66.6288 35.708 66.5508 36.008 66.5508H36.188C36.488 66.5508 36.728 66.6288 36.908 66.7848C37.1 66.9408 37.196 67.1988 37.196 67.5588C37.196 67.9188 37.1 68.1768 36.908 68.3328C36.728 68.4888 36.488 68.5668 36.188 68.5668H36.008C35.708 68.5668 35.462 68.4888 35.27 68.3328C35.09 68.1768 35 67.9188 35 67.5588Z'
      fill='url(#paint23_linear_72_662)'
    />
    <path
      d='M35 45.3752C35 45.0152 35.09 44.7572 35.27 44.6012C35.462 44.4452 35.708 44.3672 36.008 44.3672H36.188C36.488 44.3672 36.728 44.4452 36.908 44.6012C37.1 44.7572 37.196 45.0152 37.196 45.3752C37.196 45.7352 37.1 45.9932 36.908 46.1492C36.728 46.3052 36.488 46.3832 36.188 46.3832H36.008C35.708 46.3832 35.462 46.3052 35.27 46.1492C35.09 45.9932 35 45.7352 35 45.3752Z'
      fill='url(#paint24_linear_72_662)'
    />
    <path
      d='M35 23.1916C35 22.8316 35.09 22.5736 35.27 22.4176C35.462 22.2616 35.708 22.1836 36.008 22.1836H36.188C36.488 22.1836 36.728 22.2616 36.908 22.4176C37.1 22.5736 37.196 22.8316 37.196 23.1916C37.196 23.5516 37.1 23.8096 36.908 23.9656C36.728 24.1216 36.488 24.1996 36.188 24.1996H36.008C35.708 24.1996 35.462 24.1216 35.27 23.9656C35.09 23.8096 35 23.5516 35 23.1916Z'
      fill='url(#paint25_linear_72_662)'
    />
    <path
      d='M35 1.008C35 0.648 35.09 0.39 35.27 0.234C35.462 0.078 35.708 -1.31134e-08 36.008 0L36.188 7.86804e-09C36.488 2.09815e-08 36.728 0.0780001 36.908 0.234C37.1 0.39 37.196 0.648 37.196 1.008C37.196 1.368 37.1 1.626 36.908 1.782C36.728 1.938 36.488 2.016 36.188 2.016H36.008C35.708 2.016 35.462 1.938 35.27 1.782C35.09 1.626 35 1.368 35 1.008Z'
      fill='url(#paint26_linear_72_662)'
    />
    <path
      d='M0 178.477C1.57361e-08 178.117 0.0900005 177.859 0.27 177.703C0.462 177.547 0.707999 177.469 1.008 177.469H1.188C1.488 177.469 1.728 177.547 1.908 177.703C2.1 177.859 2.196 178.117 2.196 178.477C2.196 178.837 2.1 179.095 1.908 179.251C1.728 179.407 1.488 179.485 1.188 179.485H1.008C0.707999 179.485 0.462 179.407 0.27 179.251C0.0900004 179.095 -1.57361e-08 178.837 0 178.477Z'
      fill='url(#paint27_linear_72_662)'
    />
    <path
      d='M0 156.293C1.57361e-08 155.933 0.0900005 155.675 0.27 155.519C0.462 155.363 0.707999 155.285 1.008 155.285H1.188C1.488 155.285 1.728 155.363 1.908 155.519C2.1 155.675 2.196 155.933 2.196 156.293C2.196 156.653 2.1 156.911 1.908 157.067C1.728 157.223 1.488 157.301 1.188 157.301H1.008C0.707999 157.301 0.462 157.223 0.27 157.067C0.0900004 156.911 -1.57361e-08 156.653 0 156.293Z'
      fill='url(#paint28_linear_72_662)'
    />
    <path
      d='M0 134.11C1.57361e-08 133.75 0.0900005 133.492 0.27 133.336C0.462 133.18 0.707999 133.102 1.008 133.102H1.188C1.488 133.102 1.728 133.18 1.908 133.336C2.1 133.492 2.196 133.75 2.196 134.11C2.196 134.47 2.1 134.728 1.908 134.884C1.728 135.04 1.488 135.118 1.188 135.118H1.008C0.707999 135.118 0.462 135.04 0.27 134.884C0.0900004 134.728 -1.57361e-08 134.47 0 134.11Z'
      fill='url(#paint29_linear_72_662)'
    />
    <path
      d='M0 111.926C1.57361e-08 111.566 0.0900005 111.308 0.27 111.152C0.462 110.996 0.707999 110.918 1.008 110.918H1.188C1.488 110.918 1.728 110.996 1.908 111.152C2.1 111.308 2.196 111.566 2.196 111.926C2.196 112.286 2.1 112.544 1.908 112.7C1.728 112.856 1.488 112.934 1.188 112.934H1.008C0.707999 112.934 0.462 112.856 0.27 112.7C0.0900004 112.544 -1.57361e-08 112.286 0 111.926Z'
      fill='url(#paint30_linear_72_662)'
    />
    <path
      d='M0 89.7424C1.57361e-08 89.3824 0.0900005 89.1244 0.27 88.9684C0.462 88.8124 0.707999 88.7344 1.008 88.7344H1.188C1.488 88.7344 1.728 88.8124 1.908 88.9684C2.1 89.1244 2.196 89.3824 2.196 89.7424C2.196 90.1024 2.1 90.3604 1.908 90.5164C1.728 90.6724 1.488 90.7504 1.188 90.7504H1.008C0.707999 90.7504 0.462 90.6724 0.27 90.5164C0.0900004 90.3604 -1.57361e-08 90.1024 0 89.7424Z'
      fill='url(#paint31_linear_72_662)'
    />
    <path
      d='M0 67.5588C1.57361e-08 67.1988 0.0900005 66.9408 0.27 66.7848C0.462 66.6288 0.707999 66.5508 1.008 66.5508H1.188C1.488 66.5508 1.728 66.6288 1.908 66.7848C2.1 66.9408 2.196 67.1988 2.196 67.5588C2.196 67.9188 2.1 68.1768 1.908 68.3328C1.728 68.4888 1.488 68.5668 1.188 68.5668H1.008C0.707999 68.5668 0.462 68.4888 0.27 68.3328C0.0900004 68.1768 -1.57361e-08 67.9188 0 67.5588Z'
      fill='url(#paint32_linear_72_662)'
    />
    <path
      d='M0 45.3752C1.57361e-08 45.0152 0.0900005 44.7572 0.27 44.6012C0.462 44.4452 0.707999 44.3672 1.008 44.3672H1.188C1.488 44.3672 1.728 44.4452 1.908 44.6012C2.1 44.7572 2.196 45.0152 2.196 45.3752C2.196 45.7352 2.1 45.9932 1.908 46.1492C1.728 46.3052 1.488 46.3832 1.188 46.3832H1.008C0.707999 46.3832 0.462 46.3052 0.27 46.1492C0.0900004 45.9932 -1.57361e-08 45.7352 0 45.3752Z'
      fill='url(#paint33_linear_72_662)'
    />
    <path
      d='M0 23.1916C1.57361e-08 22.8316 0.0900005 22.5736 0.27 22.4176C0.462 22.2616 0.707999 22.1836 1.008 22.1836H1.188C1.488 22.1836 1.728 22.2616 1.908 22.4176C2.1 22.5736 2.196 22.8316 2.196 23.1916C2.196 23.5516 2.1 23.8096 1.908 23.9656C1.728 24.1216 1.488 24.1996 1.188 24.1996H1.008C0.707999 24.1996 0.462 24.1216 0.27 23.9656C0.0900004 23.8096 -1.57361e-08 23.5516 0 23.1916Z'
      fill='url(#paint34_linear_72_662)'
    />
    <path
      d='M0 1.008C1.57361e-08 0.648 0.0900005 0.39 0.27 0.234C0.462 0.078 0.707999 -1.31134e-08 1.008 0L1.188 7.86804e-09C1.488 2.09815e-08 1.728 0.0780001 1.908 0.234C2.1 0.39 2.196 0.648 2.196 1.008C2.196 1.368 2.1 1.626 1.908 1.782C1.728 1.938 1.488 2.016 1.188 2.016H1.008C0.707999 2.016 0.462 1.938 0.27 1.782C0.0900004 1.626 -1.57361e-08 1.368 0 1.008Z'
      fill='url(#paint35_linear_72_662)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint5_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint6_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint7_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint8_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint9_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint10_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint11_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint12_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint13_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint14_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint15_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint16_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint17_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint18_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint19_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint20_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint21_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint22_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint23_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint24_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint25_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint26_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint27_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint28_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint29_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint30_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint31_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint32_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint33_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint34_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint35_linear_72_662'
        x1='53.598'
        y1='0'
        x2='53.598'
        y2='193'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
    </defs>
  </svg>;
  return (
    <svg
      width='108'
      height='180'
      viewBox='0 0 108 180'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M105 178.477C105 178.117 105.09 177.859 105.27 177.703C105.462 177.547 105.708 177.469 106.008 177.469H106.188C106.488 177.469 106.728 177.547 106.908 177.703C107.1 177.859 107.196 178.117 107.196 178.477C107.196 178.837 107.1 179.095 106.908 179.251C106.728 179.407 106.488 179.485 106.188 179.485H106.008C105.708 179.485 105.462 179.407 105.27 179.251C105.09 179.095 105 178.837 105 178.477Z'
        fill='url(#paint0_linear_72_662)'
      />
      <path
        d='M105 156.293C105 155.933 105.09 155.675 105.27 155.519C105.462 155.363 105.708 155.285 106.008 155.285H106.188C106.488 155.285 106.728 155.363 106.908 155.519C107.1 155.675 107.196 155.933 107.196 156.293C107.196 156.653 107.1 156.911 106.908 157.067C106.728 157.223 106.488 157.301 106.188 157.301H106.008C105.708 157.301 105.462 157.223 105.27 157.067C105.09 156.911 105 156.653 105 156.293Z'
        fill='url(#paint1_linear_72_662)'
      />
      <path
        d='M105 134.11C105 133.75 105.09 133.492 105.27 133.336C105.462 133.18 105.708 133.102 106.008 133.102H106.188C106.488 133.102 106.728 133.18 106.908 133.336C107.1 133.492 107.196 133.75 107.196 134.11C107.196 134.47 107.1 134.728 106.908 134.884C106.728 135.04 106.488 135.118 106.188 135.118H106.008C105.708 135.118 105.462 135.04 105.27 134.884C105.09 134.728 105 134.47 105 134.11Z'
        fill='url(#paint2_linear_72_662)'
      />
      <path
        d='M105 111.926C105 111.566 105.09 111.308 105.27 111.152C105.462 110.996 105.708 110.918 106.008 110.918H106.188C106.488 110.918 106.728 110.996 106.908 111.152C107.1 111.308 107.196 111.566 107.196 111.926C107.196 112.286 107.1 112.544 106.908 112.7C106.728 112.856 106.488 112.934 106.188 112.934H106.008C105.708 112.934 105.462 112.856 105.27 112.7C105.09 112.544 105 112.286 105 111.926Z'
        fill='url(#paint3_linear_72_662)'
      />
      <path
        d='M105 89.7424C105 89.3824 105.09 89.1244 105.27 88.9684C105.462 88.8124 105.708 88.7344 106.008 88.7344H106.188C106.488 88.7344 106.728 88.8124 106.908 88.9684C107.1 89.1244 107.196 89.3824 107.196 89.7424C107.196 90.1024 107.1 90.3604 106.908 90.5164C106.728 90.6724 106.488 90.7504 106.188 90.7504H106.008C105.708 90.7504 105.462 90.6724 105.27 90.5164C105.09 90.3604 105 90.1024 105 89.7424Z'
        fill='url(#paint4_linear_72_662)'
      />
      <path
        d='M105 67.5588C105 67.1988 105.09 66.9408 105.27 66.7848C105.462 66.6288 105.708 66.5508 106.008 66.5508H106.188C106.488 66.5508 106.728 66.6288 106.908 66.7848C107.1 66.9408 107.196 67.1988 107.196 67.5588C107.196 67.9188 107.1 68.1768 106.908 68.3328C106.728 68.4888 106.488 68.5668 106.188 68.5668H106.008C105.708 68.5668 105.462 68.4888 105.27 68.3328C105.09 68.1768 105 67.9188 105 67.5588Z'
        fill='url(#paint5_linear_72_662)'
      />
      <path
        d='M105 45.3752C105 45.0152 105.09 44.7572 105.27 44.6012C105.462 44.4452 105.708 44.3672 106.008 44.3672H106.188C106.488 44.3672 106.728 44.4452 106.908 44.6012C107.1 44.7572 107.196 45.0152 107.196 45.3752C107.196 45.7352 107.1 45.9932 106.908 46.1492C106.728 46.3052 106.488 46.3832 106.188 46.3832H106.008C105.708 46.3832 105.462 46.3052 105.27 46.1492C105.09 45.9932 105 45.7352 105 45.3752Z'
        fill='url(#paint6_linear_72_662)'
      />
      <path
        d='M105 23.1916C105 22.8316 105.09 22.5736 105.27 22.4176C105.462 22.2616 105.708 22.1836 106.008 22.1836H106.188C106.488 22.1836 106.728 22.2616 106.908 22.4176C107.1 22.5736 107.196 22.8316 107.196 23.1916C107.196 23.5516 107.1 23.8096 106.908 23.9656C106.728 24.1216 106.488 24.1996 106.188 24.1996H106.008C105.708 24.1996 105.462 24.1216 105.27 23.9656C105.09 23.8096 105 23.5516 105 23.1916Z'
        fill='url(#paint7_linear_72_662)'
      />
      <path
        d='M105 1.008C105 0.648 105.09 0.39 105.27 0.234C105.462 0.078 105.708 -1.31134e-08 106.008 0L106.188 7.86804e-09C106.488 2.09815e-08 106.728 0.0780001 106.908 0.234C107.1 0.39 107.196 0.648 107.196 1.008C107.196 1.368 107.1 1.626 106.908 1.782C106.728 1.938 106.488 2.016 106.188 2.016H106.008C105.708 2.016 105.462 1.938 105.27 1.782C105.09 1.626 105 1.368 105 1.008Z'
        fill='url(#paint8_linear_72_662)'
      />
      <path
        d='M70 178.477C70 178.117 70.09 177.859 70.27 177.703C70.462 177.547 70.708 177.469 71.008 177.469H71.188C71.488 177.469 71.728 177.547 71.908 177.703C72.1 177.859 72.196 178.117 72.196 178.477C72.196 178.837 72.1 179.095 71.908 179.251C71.728 179.407 71.488 179.485 71.188 179.485H71.008C70.708 179.485 70.462 179.407 70.27 179.251C70.09 179.095 70 178.837 70 178.477Z'
        fill='url(#paint9_linear_72_662)'
      />
      <path
        d='M70 156.293C70 155.933 70.09 155.675 70.27 155.519C70.462 155.363 70.708 155.285 71.008 155.285H71.188C71.488 155.285 71.728 155.363 71.908 155.519C72.1 155.675 72.196 155.933 72.196 156.293C72.196 156.653 72.1 156.911 71.908 157.067C71.728 157.223 71.488 157.301 71.188 157.301H71.008C70.708 157.301 70.462 157.223 70.27 157.067C70.09 156.911 70 156.653 70 156.293Z'
        fill='url(#paint10_linear_72_662)'
      />
      <path
        d='M70 134.11C70 133.75 70.09 133.492 70.27 133.336C70.462 133.18 70.708 133.102 71.008 133.102H71.188C71.488 133.102 71.728 133.18 71.908 133.336C72.1 133.492 72.196 133.75 72.196 134.11C72.196 134.47 72.1 134.728 71.908 134.884C71.728 135.04 71.488 135.118 71.188 135.118H71.008C70.708 135.118 70.462 135.04 70.27 134.884C70.09 134.728 70 134.47 70 134.11Z'
        fill='url(#paint11_linear_72_662)'
      />
      <path
        d='M70 111.926C70 111.566 70.09 111.308 70.27 111.152C70.462 110.996 70.708 110.918 71.008 110.918H71.188C71.488 110.918 71.728 110.996 71.908 111.152C72.1 111.308 72.196 111.566 72.196 111.926C72.196 112.286 72.1 112.544 71.908 112.7C71.728 112.856 71.488 112.934 71.188 112.934H71.008C70.708 112.934 70.462 112.856 70.27 112.7C70.09 112.544 70 112.286 70 111.926Z'
        fill='url(#paint12_linear_72_662)'
      />
      <path
        d='M70 89.7424C70 89.3824 70.09 89.1244 70.27 88.9684C70.462 88.8124 70.708 88.7344 71.008 88.7344H71.188C71.488 88.7344 71.728 88.8124 71.908 88.9684C72.1 89.1244 72.196 89.3824 72.196 89.7424C72.196 90.1024 72.1 90.3604 71.908 90.5164C71.728 90.6724 71.488 90.7504 71.188 90.7504H71.008C70.708 90.7504 70.462 90.6724 70.27 90.5164C70.09 90.3604 70 90.1024 70 89.7424Z'
        fill='url(#paint13_linear_72_662)'
      />
      <path
        d='M70 67.5588C70 67.1988 70.09 66.9408 70.27 66.7848C70.462 66.6288 70.708 66.5508 71.008 66.5508H71.188C71.488 66.5508 71.728 66.6288 71.908 66.7848C72.1 66.9408 72.196 67.1988 72.196 67.5588C72.196 67.9188 72.1 68.1768 71.908 68.3328C71.728 68.4888 71.488 68.5668 71.188 68.5668H71.008C70.708 68.5668 70.462 68.4888 70.27 68.3328C70.09 68.1768 70 67.9188 70 67.5588Z'
        fill='url(#paint14_linear_72_662)'
      />
      <path
        d='M70 45.3752C70 45.0152 70.09 44.7572 70.27 44.6012C70.462 44.4452 70.708 44.3672 71.008 44.3672H71.188C71.488 44.3672 71.728 44.4452 71.908 44.6012C72.1 44.7572 72.196 45.0152 72.196 45.3752C72.196 45.7352 72.1 45.9932 71.908 46.1492C71.728 46.3052 71.488 46.3832 71.188 46.3832H71.008C70.708 46.3832 70.462 46.3052 70.27 46.1492C70.09 45.9932 70 45.7352 70 45.3752Z'
        fill='url(#paint15_linear_72_662)'
      />
      <path
        d='M70 23.1916C70 22.8316 70.09 22.5736 70.27 22.4176C70.462 22.2616 70.708 22.1836 71.008 22.1836H71.188C71.488 22.1836 71.728 22.2616 71.908 22.4176C72.1 22.5736 72.196 22.8316 72.196 23.1916C72.196 23.5516 72.1 23.8096 71.908 23.9656C71.728 24.1216 71.488 24.1996 71.188 24.1996H71.008C70.708 24.1996 70.462 24.1216 70.27 23.9656C70.09 23.8096 70 23.5516 70 23.1916Z'
        fill='url(#paint16_linear_72_662)'
      />
      <path
        d='M70 1.008C70 0.648 70.09 0.39 70.27 0.234C70.462 0.078 70.708 -1.31134e-08 71.008 0L71.188 7.86804e-09C71.488 2.09815e-08 71.728 0.0780001 71.908 0.234C72.1 0.39 72.196 0.648 72.196 1.008C72.196 1.368 72.1 1.626 71.908 1.782C71.728 1.938 71.488 2.016 71.188 2.016H71.008C70.708 2.016 70.462 1.938 70.27 1.782C70.09 1.626 70 1.368 70 1.008Z'
        fill='url(#paint17_linear_72_662)'
      />
      <path
        d='M35 178.477C35 178.117 35.09 177.859 35.27 177.703C35.462 177.547 35.708 177.469 36.008 177.469H36.188C36.488 177.469 36.728 177.547 36.908 177.703C37.1 177.859 37.196 178.117 37.196 178.477C37.196 178.837 37.1 179.095 36.908 179.251C36.728 179.407 36.488 179.485 36.188 179.485H36.008C35.708 179.485 35.462 179.407 35.27 179.251C35.09 179.095 35 178.837 35 178.477Z'
        fill='url(#paint18_linear_72_662)'
      />
      <path
        d='M35 156.293C35 155.933 35.09 155.675 35.27 155.519C35.462 155.363 35.708 155.285 36.008 155.285H36.188C36.488 155.285 36.728 155.363 36.908 155.519C37.1 155.675 37.196 155.933 37.196 156.293C37.196 156.653 37.1 156.911 36.908 157.067C36.728 157.223 36.488 157.301 36.188 157.301H36.008C35.708 157.301 35.462 157.223 35.27 157.067C35.09 156.911 35 156.653 35 156.293Z'
        fill='url(#paint19_linear_72_662)'
      />
      <path
        d='M35 134.11C35 133.75 35.09 133.492 35.27 133.336C35.462 133.18 35.708 133.102 36.008 133.102H36.188C36.488 133.102 36.728 133.18 36.908 133.336C37.1 133.492 37.196 133.75 37.196 134.11C37.196 134.47 37.1 134.728 36.908 134.884C36.728 135.04 36.488 135.118 36.188 135.118H36.008C35.708 135.118 35.462 135.04 35.27 134.884C35.09 134.728 35 134.47 35 134.11Z'
        fill='url(#paint20_linear_72_662)'
      />
      <path
        d='M35 111.926C35 111.566 35.09 111.308 35.27 111.152C35.462 110.996 35.708 110.918 36.008 110.918H36.188C36.488 110.918 36.728 110.996 36.908 111.152C37.1 111.308 37.196 111.566 37.196 111.926C37.196 112.286 37.1 112.544 36.908 112.7C36.728 112.856 36.488 112.934 36.188 112.934H36.008C35.708 112.934 35.462 112.856 35.27 112.7C35.09 112.544 35 112.286 35 111.926Z'
        fill='url(#paint21_linear_72_662)'
      />
      <path
        d='M35 89.7424C35 89.3824 35.09 89.1244 35.27 88.9684C35.462 88.8124 35.708 88.7344 36.008 88.7344H36.188C36.488 88.7344 36.728 88.8124 36.908 88.9684C37.1 89.1244 37.196 89.3824 37.196 89.7424C37.196 90.1024 37.1 90.3604 36.908 90.5164C36.728 90.6724 36.488 90.7504 36.188 90.7504H36.008C35.708 90.7504 35.462 90.6724 35.27 90.5164C35.09 90.3604 35 90.1024 35 89.7424Z'
        fill='url(#paint22_linear_72_662)'
      />
      <path
        d='M35 67.5588C35 67.1988 35.09 66.9408 35.27 66.7848C35.462 66.6288 35.708 66.5508 36.008 66.5508H36.188C36.488 66.5508 36.728 66.6288 36.908 66.7848C37.1 66.9408 37.196 67.1988 37.196 67.5588C37.196 67.9188 37.1 68.1768 36.908 68.3328C36.728 68.4888 36.488 68.5668 36.188 68.5668H36.008C35.708 68.5668 35.462 68.4888 35.27 68.3328C35.09 68.1768 35 67.9188 35 67.5588Z'
        fill='url(#paint23_linear_72_662)'
      />
      <path
        d='M35 45.3752C35 45.0152 35.09 44.7572 35.27 44.6012C35.462 44.4452 35.708 44.3672 36.008 44.3672H36.188C36.488 44.3672 36.728 44.4452 36.908 44.6012C37.1 44.7572 37.196 45.0152 37.196 45.3752C37.196 45.7352 37.1 45.9932 36.908 46.1492C36.728 46.3052 36.488 46.3832 36.188 46.3832H36.008C35.708 46.3832 35.462 46.3052 35.27 46.1492C35.09 45.9932 35 45.7352 35 45.3752Z'
        fill='url(#paint24_linear_72_662)'
      />
      <path
        d='M35 23.1916C35 22.8316 35.09 22.5736 35.27 22.4176C35.462 22.2616 35.708 22.1836 36.008 22.1836H36.188C36.488 22.1836 36.728 22.2616 36.908 22.4176C37.1 22.5736 37.196 22.8316 37.196 23.1916C37.196 23.5516 37.1 23.8096 36.908 23.9656C36.728 24.1216 36.488 24.1996 36.188 24.1996H36.008C35.708 24.1996 35.462 24.1216 35.27 23.9656C35.09 23.8096 35 23.5516 35 23.1916Z'
        fill='url(#paint25_linear_72_662)'
      />
      <path
        d='M35 1.008C35 0.648 35.09 0.39 35.27 0.234C35.462 0.078 35.708 -1.31134e-08 36.008 0L36.188 7.86804e-09C36.488 2.09815e-08 36.728 0.0780001 36.908 0.234C37.1 0.39 37.196 0.648 37.196 1.008C37.196 1.368 37.1 1.626 36.908 1.782C36.728 1.938 36.488 2.016 36.188 2.016H36.008C35.708 2.016 35.462 1.938 35.27 1.782C35.09 1.626 35 1.368 35 1.008Z'
        fill='url(#paint26_linear_72_662)'
      />
      <path
        d='M0 178.477C1.57361e-08 178.117 0.0900005 177.859 0.27 177.703C0.462 177.547 0.707999 177.469 1.008 177.469H1.188C1.488 177.469 1.728 177.547 1.908 177.703C2.1 177.859 2.196 178.117 2.196 178.477C2.196 178.837 2.1 179.095 1.908 179.251C1.728 179.407 1.488 179.485 1.188 179.485H1.008C0.707999 179.485 0.462 179.407 0.27 179.251C0.0900004 179.095 -1.57361e-08 178.837 0 178.477Z'
        fill='url(#paint27_linear_72_662)'
      />
      <path
        d='M0 156.293C1.57361e-08 155.933 0.0900005 155.675 0.27 155.519C0.462 155.363 0.707999 155.285 1.008 155.285H1.188C1.488 155.285 1.728 155.363 1.908 155.519C2.1 155.675 2.196 155.933 2.196 156.293C2.196 156.653 2.1 156.911 1.908 157.067C1.728 157.223 1.488 157.301 1.188 157.301H1.008C0.707999 157.301 0.462 157.223 0.27 157.067C0.0900004 156.911 -1.57361e-08 156.653 0 156.293Z'
        fill='url(#paint28_linear_72_662)'
      />
      <path
        d='M0 134.11C1.57361e-08 133.75 0.0900005 133.492 0.27 133.336C0.462 133.18 0.707999 133.102 1.008 133.102H1.188C1.488 133.102 1.728 133.18 1.908 133.336C2.1 133.492 2.196 133.75 2.196 134.11C2.196 134.47 2.1 134.728 1.908 134.884C1.728 135.04 1.488 135.118 1.188 135.118H1.008C0.707999 135.118 0.462 135.04 0.27 134.884C0.0900004 134.728 -1.57361e-08 134.47 0 134.11Z'
        fill='url(#paint29_linear_72_662)'
      />
      <path
        d='M0 111.926C1.57361e-08 111.566 0.0900005 111.308 0.27 111.152C0.462 110.996 0.707999 110.918 1.008 110.918H1.188C1.488 110.918 1.728 110.996 1.908 111.152C2.1 111.308 2.196 111.566 2.196 111.926C2.196 112.286 2.1 112.544 1.908 112.7C1.728 112.856 1.488 112.934 1.188 112.934H1.008C0.707999 112.934 0.462 112.856 0.27 112.7C0.0900004 112.544 -1.57361e-08 112.286 0 111.926Z'
        fill='url(#paint30_linear_72_662)'
      />
      <path
        d='M0 89.7424C1.57361e-08 89.3824 0.0900005 89.1244 0.27 88.9684C0.462 88.8124 0.707999 88.7344 1.008 88.7344H1.188C1.488 88.7344 1.728 88.8124 1.908 88.9684C2.1 89.1244 2.196 89.3824 2.196 89.7424C2.196 90.1024 2.1 90.3604 1.908 90.5164C1.728 90.6724 1.488 90.7504 1.188 90.7504H1.008C0.707999 90.7504 0.462 90.6724 0.27 90.5164C0.0900004 90.3604 -1.57361e-08 90.1024 0 89.7424Z'
        fill='url(#paint31_linear_72_662)'
      />
      <path
        d='M0 67.5588C1.57361e-08 67.1988 0.0900005 66.9408 0.27 66.7848C0.462 66.6288 0.707999 66.5508 1.008 66.5508H1.188C1.488 66.5508 1.728 66.6288 1.908 66.7848C2.1 66.9408 2.196 67.1988 2.196 67.5588C2.196 67.9188 2.1 68.1768 1.908 68.3328C1.728 68.4888 1.488 68.5668 1.188 68.5668H1.008C0.707999 68.5668 0.462 68.4888 0.27 68.3328C0.0900004 68.1768 -1.57361e-08 67.9188 0 67.5588Z'
        fill='url(#paint32_linear_72_662)'
      />
      <path
        d='M0 45.3752C1.57361e-08 45.0152 0.0900005 44.7572 0.27 44.6012C0.462 44.4452 0.707999 44.3672 1.008 44.3672H1.188C1.488 44.3672 1.728 44.4452 1.908 44.6012C2.1 44.7572 2.196 45.0152 2.196 45.3752C2.196 45.7352 2.1 45.9932 1.908 46.1492C1.728 46.3052 1.488 46.3832 1.188 46.3832H1.008C0.707999 46.3832 0.462 46.3052 0.27 46.1492C0.0900004 45.9932 -1.57361e-08 45.7352 0 45.3752Z'
        fill='url(#paint33_linear_72_662)'
      />
      <path
        d='M0 23.1916C1.57361e-08 22.8316 0.0900005 22.5736 0.27 22.4176C0.462 22.2616 0.707999 22.1836 1.008 22.1836H1.188C1.488 22.1836 1.728 22.2616 1.908 22.4176C2.1 22.5736 2.196 22.8316 2.196 23.1916C2.196 23.5516 2.1 23.8096 1.908 23.9656C1.728 24.1216 1.488 24.1996 1.188 24.1996H1.008C0.707999 24.1996 0.462 24.1216 0.27 23.9656C0.0900004 23.8096 -1.57361e-08 23.5516 0 23.1916Z'
        fill='url(#paint34_linear_72_662)'
      />
      <path
        d='M0 1.008C1.57361e-08 0.648 0.0900005 0.39 0.27 0.234C0.462 0.078 0.707999 -1.31134e-08 1.008 0L1.188 7.86804e-09C1.488 2.09815e-08 1.728 0.0780001 1.908 0.234C2.1 0.39 2.196 0.648 2.196 1.008C2.196 1.368 2.1 1.626 1.908 1.782C1.728 1.938 1.488 2.016 1.188 2.016H1.008C0.707999 2.016 0.462 1.938 0.27 1.782C0.0900004 1.626 -1.57361e-08 1.368 0 1.008Z'
        fill='url(#paint35_linear_72_662)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint10_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint11_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint12_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint13_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint14_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint15_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint16_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint17_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint18_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint19_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint20_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint21_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint22_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint23_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint24_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint25_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint26_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint27_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint28_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint29_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint30_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint31_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint32_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint33_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint34_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint35_linear_72_662'
          x1='53.598'
          y1='0'
          x2='53.598'
          y2='193'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
};
