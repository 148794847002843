import * as React from "react"
import { SVGProps } from "react"
const ShadyDotsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6rem"
        height="7rem"
        fill="none"
        viewBox="0 80 110 110"
        style={{
            position: 'absolute',
            top: 0,
            right: 50,
            height: '400px',
            // backgroundColor: 'red'
        }}
        {...props}
    >

        <path
            fill="url(#a)"
            d="M105 378.129c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#b)"
            d="M105 355.945c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#c)"
            d="M105 333.762c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#d)"
            d="M105 311.578c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#e)"
            d="M105 289.395c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#f)"
            d="M105 267.211c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#g)"
            d="M105 245.028c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#h)"
            d="M105 222.844c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#i)"
            d="M105 200.66c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#j)"
            d="M105 178.477c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#k)"
            d="M105 156.293c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#l)"
            d="M105 134.11c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#m)"
            d="M105 111.926c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#n)"
            d="M105 89.742c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#o)"
            d="M105 67.559c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#p)"
            d="M105 45.375c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#q)"
            d="M105 23.192c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#r)"
            d="M105 1.008c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#s)"
            d="M70 378.129c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#t)"
            d="M70 355.945c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#u)"
            d="M70 333.762c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#v)"
            d="M70 311.578c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#w)"
            d="M70 289.395c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#x)"
            d="M70 267.211c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#y)"
            d="M70 245.028c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#z)"
            d="M70 222.844c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#A)"
            d="M70 200.66c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#B)"
            d="M70 178.477c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#C)"
            d="M70 156.293c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#D)"
            d="M70 134.11c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#E)"
            d="M70 111.926c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#F)"
            d="M70 89.742c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#G)"
            d="M70 67.559c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#H)"
            d="M70 45.375c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#I)"
            d="M70 23.192c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#J)"
            d="M70 1.008c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#K)"
            d="M35 378.129c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#L)"
            d="M35 355.945c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#M)"
            d="M35 333.762c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#N)"
            d="M35 311.578c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#O)"
            d="M35 289.395c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#P)"
            d="M35 267.211c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#Q)"
            d="M35 245.028c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#R)"
            d="M35 222.844c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#S)"
            d="M35 200.66c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#T)"
            d="M35 178.477c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#U)"
            d="M35 156.293c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#V)"
            d="M35 134.11c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#W)"
            d="M35 111.926c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#X)"
            d="M35 89.742c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#Y)"
            d="M35 67.559c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#Z)"
            d="M35 45.375c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#aa)"
            d="M35 23.192c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#ab)"
            d="M35 1.008c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#ac)"
            d="M0 378.129c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#ad)"
            d="M0 355.945c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#ae)"
            d="M0 333.762c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#af)"
            d="M0 311.578c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#ag)"
            d="M0 289.395c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#ah)"
            d="M0 267.211c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#ai)"
            d="M0 245.028c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#aj)"
            d="M0 222.844c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#ak)"
            d="M0 200.66c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#al)"
            d="M0 178.477c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#am)"
            d="M0 156.293c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#an)"
            d="M0 134.11c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#ao)"
            d="M0 111.926c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#ap)"
            d="M0 89.742c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#aq)"
            d="M0 67.559c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#ar)"
            d="M0 45.375c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#as)"
            d="M0 23.192c0-.36.09-.618.27-.774.192-.156.438-.234.738-.234h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234-.18-.156-.27-.414-.27-.774Z"
        />
        <path
            fill="url(#at)"
            d="M0 1.008C0 .648.09.39.27.234.462.078.708 0 1.008 0h.18c.3 0 .54.078.72.234.192.156.288.414.288.774 0 .36-.096.618-.288.774-.18.156-.42.234-.72.234h-.18c-.3 0-.546-.078-.738-.234C.09 1.626 0 1.368 0 1.008Z"
        />
        <defs>
            <linearGradient
                id="a"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="b"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="c"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="d"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="e"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="f"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="g"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="h"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="i"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="j"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="k"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="l"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="m"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="n"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="o"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="p"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="q"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="r"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="s"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="t"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="u"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="v"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="w"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="x"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="y"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="z"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="A"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="B"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="C"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="D"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="E"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="F"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="G"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="H"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="I"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="J"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="K"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="L"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="M"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="N"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="O"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="P"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="Q"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="R"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="S"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="T"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="U"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="V"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="W"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="X"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="Y"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="Z"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="aa"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="ab"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="ac"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="ad"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="ae"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="af"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="ag"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="ah"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="ai"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="aj"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="ak"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="al"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="am"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="an"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="ao"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="ap"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="aq"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="ar"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="as"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="at"
                x1={53.598}
                x2={53.598}
                y1={0}
                y2={379.137}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </linearGradient>
        </defs>
    </svg>
)
export { ShadyDotsIcon }
