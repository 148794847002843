import React, {FormEvent, useState} from "react";
import { DotsShades } from "../assets/svgs/DotsShades";
import { motion } from "framer-motion";

let number1 = Math.floor(Math.random() * 10);
let number2 = Math.floor(Math.random() * 10);

function GetInTouch() {

  const [isFormValid, setFormValid] = useState(false);
  const [showMathProblem, setShowMathProblem] = useState(true);
  const [mathSolution, setMathSolution] = useState(`${number1} + ${number2} = `);
  const [mathEqual, setMathEqual] = useState(0);
  const [serverResponse, setServerResponse] = useState("");

  const nameInput = document.getElementById("name") as HTMLInputElement;
  const emailInput = document.getElementById("email") as HTMLInputElement;
  const messageInput = document.getElementById("message_footer") as HTMLTextAreaElement;
  const subjectSelect = document.getElementById("subject") as HTMLSelectElement;

  // todo az űrlaphoz kellene szöveget írni, amit a szerver küld, alert helyett, úgy lenne szebb
  const sendMail = async () => {
    try {
      const response = await fetch("https://millionairenightstoken.com/sendmail.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          name: nameInput.value,
          email: emailInput.value,
          message: messageInput.value,
          subject: subjectSelect.value,
        }).toString(),
      });

      if (response.ok) {
        const data = await response.text();
        // setServerResponse(data);
      } else {
        // setServerResponse("Hiba történt az üzenet küldése közben.");
      }
    } catch (error) {
      console.error("Hiba történt a szerverrel való kommunikáció során:", error);
      // setServerResponse("Hiba történt a szerverrel való kommunikáció során.");
    }
  };
  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (nameInput.checkValidity() && emailInput.checkValidity() && messageInput.checkValidity()) {
      if (mathEqual === number1 + number2) {
        setFormValid(true);
        sendMail();
        alert('Email sent')
        nameInput.value = '';
        emailInput.value = '';
        messageInput.value = '';
        subjectSelect.value = '';
        number1 = Math.floor(Math.random() * 10);
        number2 = Math.floor(Math.random() * 10);
        setMathEqual(0);
        setMathSolution(`${number1} + ${number2} = `);
      } else {
        number1 = Math.floor(Math.random() * 10);
        number2 = Math.floor(Math.random() * 10);
        setMathEqual(0);
        setMathSolution(`${number1} + ${number2} = `);
      }
    } else {
      setFormValid(false);
      setShowMathProblem(false);
    }

  };

  return (
    <div className=" my-16 sm:my-36 mx-5 relative flex items-center justify-center">
      <div className="absolute -mt-4  top-0  bg-[#5318D1] w-full max-w-[280px] sm:max-w-[340px] md:max-w-[570px] h-full md:h-[20px] rounded-t-[50px] z-[-10]" />
      <div className="absolute -mb-4  bottom-0   bg-[#5318D1] w-full  max-w-[280px] sm:max-w-[400px] md:max-w-[570px] h-full md:h-[20px] rounded-b-[50px] z-[-10]" />
      <div className="px-[10px] py-[30px] sm:p-[30px] lg:px-[50px] lg:py-[100px] bg-[#0F0F0F] w-full grid grid-cols-1 lg:grid-cols-2 rounded-[50px] lg:max-w-[1100px] xl:max-w-[1200px] 2xl:max-w-[1440px]">
        <div className="w-full  lg:w-[60%] flex  flex-col md:ml-20">
          <p className="texttualgradient leading-[120%]  font-normal text-[40px] text-center  lg:text-left lg:text-[50px] xl:text-[72px]">
            GET IN <br className="sm:hidden" /> TOUCH
            <span className=" ">!</span>
          </p>
          <p className=" font-light text-white text-lg lg:text-[24px] w-[85%] mt-3 lg:mt-0 lg:w-[75%] self-center lg:self-start mb-3 text-center  lg:text-left">
            If you<span className=" ">'</span>re stuck or have a question, feel
            free to contact us using one of the contact details below.
          </p>
          <div className="hidden lg:flex gap-10 mt-5 w-[400px]">
            <DotsShades />
            <DotsShades />
          </div>
        </div>

        <div className="w-full 2xl:-ml-20">
          <form onSubmit={handleFormSubmit}>
            <div className="flex items-center mb-4">
              <div className="hidden sm:block w-[200px]">
                <p className="text-lg text-white mr-5 font-normal text-right uppercase">

                </p>
              </div>
              <div className="bg-[#202020] plain_input w-full py-4 rounded-[30px] lg:rounded-[50px]">

                <select
                    id="subject"
                    name={"subject"}
                    placeholder={"What are you looking for us for?"}
                    className="bg-transparent placeholder-[red] sm:placeholder-[red] w-full px-4 text-white w-90"
                    style={{width: '93%', fontSize: '14px'}}
                    required
                >
                  <option value="">What are you looking for us for?</option>
                  <option value="Affiliate partner">Affiliate partner</option>
                  <option value="Big Investment">Big Investment</option>
                  <option value="Partnership">Partnership</option>
                </select>
              </div>
            </div>
            <div className="flex items-center mb-4">
              <div className="hidden sm:block w-[200px] ">
                <p className="text-lg text-white mr-5 font-normal text-right uppercase">
                  NAME:
                </p>
              </div>
              <div className="bg-[#202020] plain_input w-full py-4 rounded-[30px] lg:rounded-[50px]">
                <input
                    autoComplete="false"
                    id="name"
                    className="bg-transparent placeholder-[#fff] sm:placeholder-[#202020] w-full px-4 text-white"
                    placeholder="NAME:"
                    required
                />
              </div>
            </div>
            <div className="flex items-center mb-4">
              <div className="hidden sm:block w-[200px]">
                <p className="text-lg text-white mr-5 font-normal text-right uppercase">
                  Email:
                </p>
              </div>
              <div className="bg-[#202020] plain_input w-full py-4 rounded-[30px] lg:rounded-[50px]">
                <input
                    autoComplete="false"
                    id="email"
                    type="email"
                    className="bg-transparent placeholder-[#fff] sm:placeholder-[#202020] w-full px-4 text-white"
                    placeholder="EMAIL :"
                    required
                />
              </div>
            </div>
            <div className="flex items-start mb-4">
              <div className="hidden sm:block w-[200px]">
                <p className="mt-2 text-lg text-white mr-5 font-normal text-right uppercase">
                  Message:
                </p>
              </div>
              <div className="bg-[#202020] plain_input w-full py-4 rounded-[30px] lg:rounded-[50px]">
            <textarea
                id="message_footer"
                autoComplete="false"
                rows={10}
                className="resize-none w-full py-2 placeholder-[#fff] sm:placeholder-[#202020] bg-transparent px-4 text-white placeholder-shown:visible"
                placeholder="MESSAGE:"
                required
            />
              </div>
            </div>
            {showMathProblem && (
                <div className="flex items-center mb-4 w-full">
                  <div className="hidden sm:block w-[200px] ">
                    <p className="text-lg text-white mr-5 font-normal text-right uppercase">
                      {mathSolution}
                    </p>
                  </div>
                  <div className="bg-[#202020] plain_input w-full py-4 rounded-[30px] lg:rounded-[50px]">
                    <input
                        type="number"
                        value={mathEqual ? mathEqual : ''}
                        onChange={(e) => setMathEqual(+e.target.value)}
                        className="bg-transparent placeholder-[#fff] sm:placeholder-[#202020] w-full px-4 text-white"
                        placeholder={mathSolution + ' ?'}
                        required
                    />
                  </div>
                </div>
            )}
            <div className="flex justify-center md:justify-start sm:ml-[150px]">
              <motion.button
                  type="submit"
                  whileHover={{
                    scale: 1.05,
                    transition: { duration: 1 },
                  }}
                  className="bg-gradient-to-t font-semibold from-[#5318D1] to-[#C85EF7] px-5 rounded-3xl py-[14px] text-white"
              >
                Send Message
              </motion.button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export { GetInTouch };
