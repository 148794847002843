import React from "react";
import { BackIcon, OfferDotsIcon } from "../assets";
import { GradientButton } from "./GradientButton";
import { motion } from "framer-motion";
import { PolgariDanielPng, MeszarosRichardPng, ApatiGaborPng } from "../assets";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { DotsShades } from "../assets/svgs/DotsShades";

import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";

const details = [
    {
        name: (<span className="text-white">Daniel Polgari<br/>CEO</span>),
        img: PolgariDanielPng
	},
	{
        name: (<span className="text-white">Richard Meszaros<br/>COO</span>),
        img: MeszarosRichardPng
	},
	{
        name: (<span className="text-white">Gabor Apati<br/>CMO</span>),
        img: ApatiGaborPng
	}
];

const Pager = () => {
	const swiper = useSwiper();
  
	return (
		<div className="absolute w-full" style={{top: "80px", zIndex: 1000}}>
			<button
				style={{left: 0, width: "24px", height: "auto"}}
				className="absolute"
				onClick={(event) => {
					swiper.slidePrev();
					event.preventDefault();
				}}
			>
				<img src={BackIcon} className="w-[100%]" alt="" />
			</button>
			<button
				style={{right: 0, width: "24px", height: "auto"}}
				className="absolute rotate-180 w-24 "
				onClick={(event) => {
					swiper.slideNext();
					event.preventDefault();
				}}
			>
				<img src={BackIcon} className="w-[100%]" alt="" />
			</button>
		</div>
	);
  };

const SwiperCard = () => {
	const swiper = useSwiper();

	return (
		<Swiper
			cssMode={true}
			mousewheel={true}
			keyboard={true}
			modules={[Navigation, Pagination, Mousewheel, Keyboard]}
			className="mySwiper img-fullwidth text-black h-full "
		>
		  {details.map((item, index) => {
			return (
				<SwiperSlide key={index}>
				  <div className="flex flex-col items-center justify-center">
					<div className="box rounded-full flex w-[205px] h-[205px] justify-center items-center" style={{boxShadow: "0px 4px 4px 0px #00000040", background: "radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 85%, #5318D1 100%"}}>
						<img className="rounded-full w-[80%]" src={item.img}/>
					</div>
					<p className="mt-5">{item.name}</p>
					<div className="mt-2">
						<DotsShades/>
					</div>
        		  </div>				  
				</SwiperSlide>
			);
		  })}
		  <Pager/>
		</Swiper>
	);
  };


const TeamsSectionMobile = () => {
  return (
    <div className='mt-16 mb-24 w-[90%] md:w-[75%] m-auto '>
      <div className='text-white md:flex flex-col justify-center items-center'>
          <p className='text-center text-[24px] md:text-[42px]  font-normal uppercase mb-10'>
            TEAMS
          </p>
		  <SwiperCard/>
		  
	  </div>
	</div>
  );
};

export { TeamsSectionMobile };
