import React, { SVGProps } from "react";

type mode = "facebook" | "instagram" | "twitter" | "vimeo" | "telegram" | "linktree" | "medium" | "youtube" | "tiktok" | "discord";
interface Props extends SVGProps<SVGSVGElement> {
    mode: mode;
    size?: number;
}

const SocialIcons = (props: Props) => {
    return (
        <>
            {props.mode === "facebook" && (
                <img
                    src="/assets/images/facebook.svg"
                    alt="facebook"
                    width="8px"
                    height="18px"
                    style={{
                        objectFit: "contain",
                    }}
                />
            )}
            {props.mode === "instagram" && (
                <img
                    src="/assets/images/instagram.svg"
                    alt="instagram"
                    width="20px"
                    height="20px"
                    style={{
                        objectFit: "contain",
                    }}
                />
            )}
            {props.mode === "twitter" && (
                <img
                    src="/assets/images/twitter.svg"
                    alt="twitter"
                    width="18px"
                    height="15px"
                    style={{
                        objectFit: "contain",
                    }}
                />
            )}
            {props.mode === "vimeo" && (
                <img
                    src="/assets/images/vimeo.svg"
                    alt="vimeo"
                    width="18px"
                    height="15px"
                    style={{
                        objectFit: "contain",
                    }}
                />
            )}
            {props.mode === "telegram" && (
                <img
                    src="/assets/images/telegram.svg"
                    alt="telegram"
                    width="YOUR_TELEGRAM_WIDTH"
                    height="YOUR_TELEGRAM_HEIGHT"
                    style={{
                        objectFit: "contain",
                    }}
                />
            )}
            {props.mode === "linktree" && (
                <img
                    src="/assets/images/linktree.svg"
                    alt="linktree"
                    width="YOUR_LINKTREE_WIDTH"
                    height="YOUR_LINKTREE_HEIGHT"
                    style={{
                        objectFit: "contain",
                    }}
                />
            )}
            {props.mode === "medium" && (
                <img
                    src="/assets/images/medium.svg"
                    alt="medium"
                    width="YOUR_MEDIUM_WIDTH"
                    height="YOUR_MEDIUM_HEIGHT"
                    style={{
                        objectFit: "contain",
                    }}
                />
            )}
            {props.mode === "youtube" && (
                <img
                    src="/assets/images/youtube.svg"
                    alt="youtube"
                    width="YOUR_YOUTUBE_WIDTH"
                    height="YOUR_YOUTUBE_HEIGHT"
                    style={{
                        objectFit: "contain",
                    }}
                />
            )}
            {props.mode === "tiktok" && (
                <img
                    src="/assets/images/tiktok.svg"
                    alt="tiktok"
                    width="YOUR_TIKTOK_WIDTH"
                    height="YOUR_TIKTOK_HEIGHT"
                    style={{
                        objectFit: "contain",
                    }}
                />
            )}
            {props.mode === "discord" && (
                <img
                    src="/assets/images/discord.svg"
                    alt="tiktok"
                    width="YOUR_TIKTOK_WIDTH"
                    height="YOUR_TIKTOK_HEIGHT"
                    style={{
                        objectFit: "contain",
                    }}
                />
            )}
        </>
    );
};

export { SocialIcons };
